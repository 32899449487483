import React, { setGlobal } from "reactn";
import ReactDOM from "react-dom";
import Main from "./routes/Main";
import "typeface-roboto";
import "./styles/index.css";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// Firebase
import firebase from "firebase/app";
import "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyB5m8pDNuuvSOVUfL9HBFSxnYh21WpEINE",
  authDomain: "imagine-radio-1a05a.firebaseapp.com",
  databaseURL: "https://imagine-radio-1a05a.firebaseio.com",
  projectId: "imagine-radio-1a05a",
  storageBucket: "imagine-radio-1a05a.appspot.com",
  messagingSenderId: "90129944248",
  appId: process.env.REACT_APP_fb_appId,
  measurementId: process.env.REACT_APP_measurementId
};
firebase.initializeApp(firebaseConfig);
firebase.analytics().logEvent('notification_received');

setGlobal({
  audioAdAfterNumberOfSongs: 3,
  songsPlayed: 0,
  audioAdCampaignRunning: false,
  playingAudioAd: false,
  adQ: [],
  errorText: '',
  authType: '',
  playbackState: false,
  livePlayerMode: true,
  multiPlayerMode: false,
  videoPlayerMode: false,
  playerSource: "",
  playerQueueSource: [],
  playerQueueLength: 0,
  playerCurrentTrackIndex: 0,
  playerCurrentTrackId: 0,
  playerQueueTrackIds: [],
  playerCurrentTrackHasLike: false,
  playerQueueTrackHasLike: [],
  playerCurrentTrackTitle: "",
  playerQueueTrackTitle: [],
  playerCurrentTrackArtist: "",
  playerQueueTrackArtist: [],
  playerCurrentTrackArt: "",
  playerQueueTrackArt: [],
  playerCurrentTrackDuration: 0,
  playerQueueTrackDurations: [],
  liveCover: '',
  playingFromId: '',
  playingFromTitle: '',
  playingFromSubtitle: '',
  playingFromArt: '',
  playingFromHasLike: false,
  adEligible: true,
  playerCurrentTrackLyrics: '',
  hasLyrics: false,
  queueAvailable: false,
});
function checkLogin() {
  if(localStorage.getItem('token') === 'null' || typeof(localStorage.getItem('token')) === 'object') {
    setGlobal({loggedIn: false})
  } else {
    setGlobal({loggedIn: true})
  }
}
checkLogin()
ReactDOM.render(<Main />, document.getElementById("root"));

serviceWorkerRegistration.register();