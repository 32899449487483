import React, { Component, Suspense } from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";

const Login = React.lazy(() => import("../pages/Login"));
const EmailLogin = React.lazy(() => import("../pages/EmailLogin"));
const Toffee = React.lazy(() => import("../pages/Toffee"));
const Signup = React.lazy(() => import("../pages/Signup"));
const ForgetPassword = React.lazy(() => import("../pages/ForgetPassword"));
const Home = React.lazy(() => import("../pages/Home"));
const Bangla = React.lazy(() => import("../pages/Bangla"));
const Discover = React.lazy(() => import("../pages/Discover"));
const Library = React.lazy(() => import("../pages/Library"));
const Profile = React.lazy(() => import("../pages/Profile"));
const ProfileEdit = React.lazy(() => import("../pages/ProfileEdit"));
const JustPlay = React.lazy(() => import("../pages/JustPlay"));
const LevelDetail = React.lazy(() => import("../pages/LevelDetail"));
const LoginLayout = React.lazy(() => import("../layouts/LoginLayout"));
const AppLayout = React.lazy(() => import("../layouts/AppLayout"));

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout>
        <Component {...props} />
      </Layout>
    )}
  />
);

class Main extends Component {
  render() {
    return (
      <BrowserRouter>
        <Suspense
          fallback={
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "40%",
                fontSize: "20px",
              }}
            >
              Loading...
            </div>
          }
        >
          <Switch>
            <AppRoute exact path="/" layout={LoginLayout} component={Login} />
            <AppRoute
              exact
              path="/login"
              layout={LoginLayout}
              component={EmailLogin}
            />
            <AppRoute
              exact
              path="/toffee"
              layout={LoginLayout}
              component={Toffee}
            />
            <AppRoute
              exact
              path="/Signup"
              layout={LoginLayout}
              component={Signup}
            />
            <AppRoute
              exact
              path="/forgetpassword"
              layout={LoginLayout}
              component={ForgetPassword}
            />
            <AppRoute exact path="/Home" layout={AppLayout} component={Home} />
            <AppRoute
              exact
              path="/Bangla"
              layout={AppLayout}
              component={Bangla}
            />
            <AppRoute
              exact
              path="/Discover"
              layout={AppLayout}
              component={Discover}
            />
            <AppRoute
              exact
              path="/Library"
              layout={AppLayout}
              component={Library}
            />
            <AppRoute
              exact
              path="/Profile"
              layout={AppLayout}
              component={Profile}
            />
            <AppRoute
              exact
              path="/EditProfile"
              layout={AppLayout}
              component={ProfileEdit}
            />
            <AppRoute
              exact
              path="/play/:uri"
              layout={AppLayout}
              component={JustPlay}
            />
            <AppRoute
              exact
              path="/:levelType/:levelTitle"
              layout={AppLayout}
              component={LevelDetail}
            />
          </Switch>
        </Suspense>
      </BrowserRouter>
    );
  }
}

export default Main;
